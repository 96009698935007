import axios from 'axios';
import regeneratorRuntime from 'regenerator-runtime';

// Local Django server API endpoint
const API_BASE_URL = 'http://127.0.0.1:8000/activity/get-presigned-url/1/';

// Function to get CSRF token from cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const getPresignedUrl = async (filename, operation = 'upload') => {
    try {
        console.log('Requesting file operation:', { filename, operation });
        const csrfToken = getCookie('csrftoken');
        
        const response = await fetch(API_BASE_URL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken || ''
            },
            body: JSON.stringify({
                filename,
                operation
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('File operation response:', data);
        return data;
    } catch (error) {
        console.error('Error in file operation:', {
            error: error.message,
            url: API_BASE_URL
        });
        throw error;
    }
};

export const uploadToS3 = async (file, filename) => {
    try {
        console.log('Uploading file to server:', filename);
        const csrfToken = getCookie('csrftoken');
        
        // Create FormData to send the file
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', filename);
        formData.append('operation', 'upload');

        const presignedUrl = await getPresignedUrl(filename, 'upload');
        
        // const response = await fetch(API_BASE_URL, {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: {
        //         'X-CSRFToken': csrfToken || ''
        //     },
        //     body: formData
        // });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('File upload successful:', data);
        return data;
    } catch (error) {
        console.error('Error uploading file:', {
            error: error.message
        });
        throw error;
    }
};

export const downloadFromS3 = async (filename) => {
    try {
        console.log('Downloading file from server:', filename);
        const csrfToken = getCookie('csrftoken');
        
        const response = await fetch(API_BASE_URL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken || ''
            },
            body: JSON.stringify({
                filename,
                operation: 'download'
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        console.log('File download successful');
        return blob;
    } catch (error) {
        console.error('Error downloading file:', {
            error: error.message
        });
        throw error;
    }
}; 