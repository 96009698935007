import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { projectTitleInitialState } from "../reducers/project-title";
import downloadBlob from "../lib/download-blob";
import AWS from "aws-sdk";
import regeneratorRuntime from "regenerator-runtime";

/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */
class SB3Downloader extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["downloadProject"]);
        this.state = {
            oldContent: null,
        };
    }
    // componentDidMount() {
    //     this.interval = setInterval(this.downloadProject, 60000);
    // }
    componentWillUnmount() {
        // clearInterval(this.interval);
    }
    uploadFileToS3(filename, content) {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_S3_REGION,
        });

        const s3 = new AWS.S3();
        const query = new URLSearchParams(window.location.search);
        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: `scratch/${query.get("activityId") || "activity"}_${query.get(
                "user"
            )}.sb3`,
            Body: content,
        };

        try {
            s3.upload(params)
                .promise()
                .then(() => {
                    console.log("File uploaded successfully!");
                });
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }
    downloadProject() {
        this.props.saveProjectSb3().then((content) => {
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
            // downloadBlob(this.props.projectFilename, content);
            // if (this.state.oldContent === null || this.state.oldContent.size !== content.size) {
            //     this.uploadFileToS3(this.props.projectFilename, content);
            //     this.setState({ oldContent: content });
            // }
            this.uploadFileToS3(this.props.projectFilename, content);
        });
    }
    render() {
        const { children } = this.props;
        return children(this.props.className, this.downloadProject);
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
};
SB3Downloader.defaultProps = {
    className: "",
};

const mapStateToProps = (state) => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(
        state.scratchGui.vm
    ),
    projectFilename: getProjectFilename(
        state.scratchGui.projectTitle,
        projectTitleInitialState
    ),
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Downloader);
